// components/Services.js
import React from "react";
import ServiceImage1 from "../images/service1.jpg";
import ServiceImage2 from "../images/service2.jpg";
import ServiceImage3 from "../images/service3.jpg";

const Services = () => {
  return (
    <section className="bg-black md:px-10 lg:px-24 py-28 pb-40 flex flex-col justify-center">
      <div className="text-left mb-20">
        <h2 className="text-4xl md:text-7xl font-bold text-[#6bfff7] uppercase tracking-wide michroma-bold text-left orbitron-regular">
          Our Services
        </h2>
      </div>

      <div className="flex flex-col md:flex-row justify-center items-center gap-12">
        {/* Service 1 */}
        <div className="text-left md:w-1/3">
          <div className="flex justify-center mb-6">
            <img
              src={ServiceImage1}
              alt="Custom Software Development"
              className="w-40 h-40 md:w-48 md:h-48 rounded-full object-cover border-4 border-[#6bfff7] shadow-lg"
            />
          </div>
          <h3 className="text-2xl font-bold uppercase mb-2 orbitron-regular text-yellow-300">
            Custom Software Development
          </h3>
          <p className="text-xl px-4 md:px-0 dm-sans-thin">
            We design and build custom software applications that help your
            business scale and operate efficiently. From web apps to mobile
            solutions, our team ensures every product is user-friendly, secure,
            and aligned with your goals.
          </p>
        </div>

        {/* Service 2 */}
        <div className="text-left md:w-1/3">
          <div className="flex justify-center mb-6">
            <img
              src={ServiceImage2}
              alt="Full-Stack Development"
              className="w-40 h-40 md:w-48 md:h-48 rounded-full object-cover border-4 border-[#6bfff7] shadow-lg"
            />
          </div>
          <h3 className="text-2xl font-bold uppercase mb-2 orbitron-regular text-yellow-300 orbitron-regular">
            Full-Stack Development
          </h3>
          <p className="text-xl px-4 md:px-0 dm-sans-thin">
            Our full-stack development services cover both frontend and backend
            technologies, including React.js, Node.js, PostgreSQL, and more. We
            create scalable, high-performance applications that deliver seamless
            user experiences.
          </p>
        </div>

        {/* Service 3 */}
        <div className="text-left md:w-1/3">
          <div className="flex justify-center mb-6">
            <img
              src={ServiceImage3}
              alt="API Integration and Development"
              className="w-40 h-40 md:w-48 md:h-48 rounded-full object-cover border-4 border-[#6bfff7] shadow-lg"
            />
          </div>
          <h3 className="text-2xl font-bold uppercase mb-2 orbitron-regular text-yellow-300">
            API Integration and Development
          </h3>
          <p className="text-xl px-4 md:px-0 dm-sans-thin">
            We offer expert API integration services, allowing your systems to
            communicate efficiently. Whether you need third-party API
            integration or custom API development, we ensure smooth and secure
            data flow between platforms.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Services;
