// components/Faq.js
import React from "react";

const Faq = () => {
  return (
    <section className="bg-teal-500 text-black py-16 px-8 md:px-12 lg:px-24 py-40 flex flex-col justify-center">
      <div className="text-center mb-12 py-10">
        <h2 className="text-4xl md:text-7xl font-bold uppercase tracking-wide orbitron-regular text-left">
          FAQs
        </h2>
      </div>

      <div className="flex flex-col md:flex-row justify-center items-start gap-12">
        {/* FAQ 1 */}
        <div className="md:w-1/3 text-left flex flex-col">
          <h3 className="text-lg md:text-3xl text-yellow-300 uppercase mb-4 orbitron-thin">
            How long does it take to develop a custom solution?
          </h3>
          <p className="text-2xl mb-6 dm-sans-regular">
            The timeline depends on the complexity of the project, but we follow
            an Agile process to deliver results incrementally and as quickly as
            possible.
          </p>
          <div className="flex items-center">
            <span className="h-1 w-1/3 bg-black mr-2"></span>
            <div className="flex gap-2">
              <span className="h-4 w-4 bg-cyan-300"></span>
              <span className="h-4 w-4 bg-cyan-400"></span>
              <span className="h-4 w-4 bg-yellow-300"></span>
            </div>
          </div>
        </div>

        {/* FAQ 2 */}
        <div className="md:w-1/3 text-left">
          <h3 className="text-lg md:text-3xl text-yellow-300 uppercase mb-4 orbitron-thin">
            What industries do you specialize in?
          </h3>
          <p className="text-2xl mb-6 dm-sans-regular">
            We work across a variety of industries, including eCommerce, health
            tech, and financial services, to deliver scalable and secure
            software solutions.
          </p>
          <div className="flex items-center">
            <span className="h-1 w-1/3 bg-black mr-2"></span>
            <div className="flex gap-2">
              <span className="h-4 w-4 bg-cyan-300"></span>
              <span className="h-4 w-4 bg-cyan-400"></span>
              <span className="h-4 w-4 bg-yellow-300"></span>
            </div>
          </div>
        </div>

        {/* FAQ 3 */}
        <div className="md:w-1/3 text-left">
          <h3 className="text-lg md:text-3xl text-yellow-300 uppercase mb-4 orbitron-thin">
            Can you integrate our current systems with new software?
          </h3>
          <p className="text-2xl mb-6 dm-sans-regular">
            Yes, we specialize in API integrations, ensuring that your new
            software communicates effectively with your existing platforms.
          </p>
          <div className="flex items-center">
            <span className="h-1 w-1/3 bg-black mr-2"></span>
            <div className="flex gap-2">
              <span className="h-4 w-4 bg-cyan-300"></span>
              <span className="h-4 w-4 bg-cyan-400"></span>
              <span className="h-4 w-4 bg-yellow-300"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
