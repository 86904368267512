// components/Contact.js
import React from "react";
import BeyondCodebackground from "../videos/BeyondCode-background.mp4";

const Contact = () => {
  return (
    <section className="relative bg-black h-screen flex flex-col justify-center items-start overflow-hidden">
      {/* Background Video */}
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 w-full h-full object-cover z-0"
      >
        <source src={BeyondCodebackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Text Content */}
      <div className="relative z-10 p-8 md:p-16 lg:p-24">
        <h2 className="text-4xl md:text-6xl font-bold uppercase tracking-wide mb-4 orbitron-regular text-left text-[#6bfff7] w-2/3">
          Get a Free Quote. <br /> Call or Send Us an Email Today!
        </h2>

        <div className="mt-12 text-left">
          <h3 className="text-lg md:text-3xl font-bold uppercase text-[#fafb63] mb-2">
            Email
          </h3>
          <p className="text-md md:text-2xl text-[#fafb63] dm-sans-thin">
            hello.beyondcode@gmail.com
          </p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
