// components/About.js
import React from "react";
import TeamImage from "../images/about.jpg";

const About = () => {
  return (
    <section className="relative bg-black pt-48 pb-10 flex flex-col justify-center items-center overflow-hidden">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-center px-6 md:px-12 py-16">
        {/* Image Section */}
        <div className="w-full md:w-1/2 flex justify-center mb-10 md:mb-0">
          <img
            src={TeamImage}
            alt="Team Working"
            className="w-5/6 md:w-4/5 rounded-[20%] shadow-lg"
            style={{
              clipPath: "polygon(30% 0%, 100% 0%, 100% 100%, 30% 100%, 0% 50%)",
            }}
          />
        </div>
        {/* Text Content Section */}
        <div className="w-full md:w-1/2 text-left">
          <h2 className="text-3xl md:text-4xl font-bold uppercase tracking-wide mb-4 text-yellow-300">
            Empowering Businesses with Cutting-Edge Technology
          </h2>
          <p className="text-lg md:text-xl font-medium">
            At Beyondcode Software Solutions, we specialize in creating
            innovative software solutions tailored to meet the unique needs of
            businesses. Whether you're looking for full-stack development, API
            integrations, or custom software to enhance your operations, we’re
            here to help you go beyond coding and deliver impactful digital
            experiences.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
