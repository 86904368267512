// components/Header.js
import React from "react";
import BeyondCodebackground from "../videos/BeyondCode-background.mp4";
import logo from "../images/logo.png";

const Header = () => {
  return (
    <header className="relative bg-black text-yellow-300 h-screen flex flex-col justify-center items-center overflow-hidden">
      {/* Background Video */}
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 w-full h-full object-cover z-0"
      >
        <source src={BeyondCodebackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Optional Overlay to Enhance Text Visibility */}
      <div className="absolute inset-0 bg-black opacity-40 z-0"></div>

      {/* Text Content */}
      <div className="relative z-10 text-left ml-30">
        <div className="flex flex-row ml-40">
          <img
            src={logo}
            alt="BeyondCode logo"
            className="w-10 h-12 mt-3 mr-4"
          />
          <h1 className="text-4xl md:text-6xl font-bold uppercase tracking-wider mb-4">
            Beyond Code
            <span className="block text-xl md:text-2xl font-medium mb-10">
              Software Solutions
            </span>
          </h1>
        </div>
        <h2 className="text-4xl md:text-7xl uppercase tracking-wide michroma-bold w-1/2 ml-40 leading-8">
          Software Development Services
        </h2>
      </div>
    </header>
  );
};

export default Header;
