// components/WhyChooseUs.js
import React from "react";
import { FiCloud, FiEye, FiGrid } from "react-icons/fi";

const WhyChooseUs = () => {
  return (
    <section className="bg-black px-8 md:px-12 lg:px-24  pb-48 pt-20 flex justify-center flex-col">
      <div className="text-center mb-12">
        <h2 className="text-4xl md:text-7xl pb-8 font-bold text-[#6bfff7] uppercase tracking-wide orbitron-regular text-left w-1/2">
          Why Choose Beyondcode?
        </h2>
      </div>

      <div className="flex flex-col md:flex-row justify-center items-center gap-12">
        {/* Feature 1 */}
        <div className="text-center md:w-1/3">
          <div className="flex justify-center mb-6">
            <div className="border-8 border-yellow-400 px-20 py-10">
              <FiCloud className="text-6xl md:text-9xl text-[#6bfff7]" />
            </div>
          </div>
          <h3 className="text-2xl font-bold uppercase mb-2 text-[#6bfff7] text-nowrap">
            Customized to Your Business
          </h3>
          <p className="text-lg px-4 md:px-0 dm-sans-thin">
            Our solutions are designed specifically to meet the individual needs
            of your business. We take the time to understand your challenges and
            deliver solutions that align perfectly with your objectives.
          </p>
        </div>

        {/* Feature 2 */}
        <div className="text-center md:w-1/3">
          <div className="flex justify-around mb-6">
            <div className="border-8 border-yellow-400 px-20 py-10">
              <FiEye className="text-6xl md:text-9xl text-[#6bfff7]" />
            </div>
          </div>
          <h3 className="text-2xl font-bold uppercase mb-2 text-[#6bfff7]">
            Expert Recommendations
          </h3>
          <p className="text-lg px-4 md:px-0 dm-sans-thin">
            With years of experience in full-stack development and technology
            consulting, we provide expert recommendations tailored to your
            industry and operational goals.
          </p>
        </div>

        {/* Feature 3 */}
        <div className="text-center md:w-1/3">
          <div className="flex justify-around mb-6">
            <div className="border-8 border-yellow-400 px-20 py-10">
              <FiGrid className="text-6xl md:text-9xl text-[#6bfff7]" />
            </div>
          </div>
          <h3 className="text-2xl font-bold uppercase mb-2 text-[#6bfff7]">
            Dedicated Support
          </h3>
          <p className="text-xl px-4 md:px-0 dm-sans-thin">
            Each client receives personalized attention, with an assigned
            service representative to guide you through the development process
            and provide ongoing support.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
