// components/Testimonials.js
import React from "react";
import ClientImage1 from "../images/client1.jpg"; // Add your image paths here
import ClientImage2 from "../images/client2.jpg";

const Testimonials = () => {
  return (
    <section className="bg-black md:px-12 lg:px-24  py-48 flex justify-between flex-col">
      <div className="text-center mb-12">
        <h2 className="text-4xl md:text-7xl font-bold uppercase tracking-wide orbitron-regular text-left text-[#6bfff7] pb-12">
          Early Reviews
        </h2>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center w-5/6">
        <div className="flex flex-col">
          {/* Testimonial 1 */}
          <div className="md:w-1/2 text-left flex flex-col">
            <p className="text-xl mb-6 dm-sans-thin dm-sans-regular">
              Beyondcode Software Solutions transformed our outdated system into
              a modern, scalable platform. Their attention to detail and
              expertise in full-stack development was exactly what we needed.
            </p>
            <div className="flex flex-row justify-between">
              <div className="flex flex-col justify-end">
                <h4 className="text-yellow-300 text-lg font-bold uppercase mr-4 orbitron-regular w-1/2">
                  Client Confidential
                </h4>
                <div className="flex gap-2">
                  <span className="h-4 w-4 bg-cyan-300"></span>
                  <span className="h-4 w-4 bg-cyan-400"></span>
                  <span className="h-4 w-4 bg-yellow-300"></span>
                </div>
              </div>
              <div className="w-20 h-20 md:w-24 md:h-24 rounded-full overflow-hidden border-2 border-yellow-300 shadow-lg">
                {" "}
                <img
                  src={ClientImage1}
                  alt="Client 1"
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Placeholder for Next Client */}
        <div className="text-left md:w-1/2 pt-28">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col justify-end">
              <h4 className="text-yellow-300 text-xl font-bold uppercase mr-4 orbitron-regular">
                This could be you
              </h4>
              <div className="flex gap-2">
                <span className="h-4 w-4 bg-cyan-300"></span>
                <span className="h-4 w-4 bg-cyan-400"></span>
                <span className="h-4 w-4 bg-yellow-300"></span>
              </div>
            </div>
            <div className="w-20 h-20 md:w-24 md:h-24 rounded-full overflow-hidden border-2 border-yellow-300 shadow-lg">
              {" "}
              <img
                src={ClientImage1}
                alt="Client 1"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
